import { Injectable } from '@angular/core';
import { PublicApiService } from '../api/public-api.service';
import { Observable } from 'rxjs';
import { ApiResponse } from '../../models';
import { EvaluatedFeatureFlag } from '@novisto/common';

@Injectable({ providedIn: 'root' })
export class PublicFeatureFlagApiService {
  private readonly resource: string;
  private readonly servicePath: string;

  constructor(private readonly publicApiService: PublicApiService) {
    this.servicePath = this.publicApiService.getServicePath();
    this.resource = this.publicApiService.apiConfig.apis.external.resources.featureFlags;
  }

  public evaluateAllFeatureFlags(): Observable<ApiResponse<EvaluatedFeatureFlag[]>> {
    return this.publicApiService.post<ApiResponse<EvaluatedFeatureFlag[]>>(
      `${this.servicePath}${this.resource}/feature_flags/evaluate`,
    );
  }
}
