import { NgModule } from '@angular/core';
import { Routes } from '@angular/router';
import { RouterModule } from '@angular/router';

import { AppRoutes } from './shared/routes/app.routes';

export const appRoutes: Routes = [
  {
    path: AppRoutes.AUTH,
    loadChildren: () => import('./auth/auth.module').then((mod) => mod.AuthModule),
    data: { preload: false },
  },
  {
    path: AppRoutes.APP,
    loadChildren: () => import('./private/private.module').then((mod) => mod.PrivateModule),
    data: { preload: true },
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, {
      useHash: true,
      initialNavigation: 'enabledBlocking',
      scrollPositionRestoration: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
