import { Injectable } from '@angular/core';

import { CoreApiService } from '@novisto/common';

import { ApplicationApiDefinition } from '../../models';
import { PublicApiService } from '../api/public-api.service';

@Injectable({ providedIn: 'root' })
export class PublicCoreApiService extends CoreApiService {
  public readonly apiName: keyof ApplicationApiDefinition = 'public';
  public readonly resource: string;
  public readonly servicePath: string;

  constructor(readonly apiService: PublicApiService) {
    super();
    this.servicePath = this.apiService.getServicePath();
    this.resource = this.apiService.apiConfig.apis.external.resources.units;
  }
}
