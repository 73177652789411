import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { BYPASS_INTERCEPTOR_ERROR_MANAGING } from '@novisto/common';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { AuthService } from '../../services/auth/auth.service';

@Injectable()
export class UnauthorizedErrorInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err: unknown) => {
        if (err instanceof HttpErrorResponse && err.status === 403 && !request.url.includes('/token')) {
          return this.authService.refreshToken(request, next);
        } else if (!request.context.get(BYPASS_INTERCEPTOR_ERROR_MANAGING)) {
          return this.handleError(err as HttpErrorResponse);
        } else {
          return next.handle(request);
        }
      }),
    );
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 403) {
      this.authService.logout();
    }

    return throwError(() => error);
  }
}
