import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';

import { addTokenHeader } from '../../utilities/auth-utils';
import { AuthService } from '../../services/auth/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private readonly authService: AuthService) {}

  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token = this.authService.user || this.authService.token;

    if (!this.isNovistoService(request.url) || !token) {
      return next.handle(request);
    }

    request = addTokenHeader(request, token);

    return next.handle(request);
  }

  private isNovistoService(requestUrl: string): boolean {
    const urls = ['novisto.net', 'localhost'];
    return urls.some((url: string) => requestUrl.includes(url));
  }
}
