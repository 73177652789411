import { Injectable } from '@angular/core';

import { Observable, defer, from } from 'rxjs';
import { FormatNumberBrowserLocalePipe, LoaderStateService, TranslateService, UnitSymbolPipe } from '@novisto/common';

import { EmbedderUtils } from '../../utilities/embedder-utils';
import {
  EmbedderFontColors,
  EmbedderHighlightColors,
  EmbedderInsertOptions,
  EmbedderValue,
  EmbedderValueField,
  EmbedderValueId,
  IContextSettings,
  MinimalDocumentMetaData,
  UpdateEmbedderValuesOptions,
  ValueDefinitionType,
  ValueGroup,
} from '../../models';
import { EmbedderService } from '../embedder/embedder.service';
import { PublicDocumentsService } from '../public-documents/public-documents.service';
import { PublicIndicatorsService } from '../public-indicators/public-indicators.service';
import chunk from 'lodash/chunk';
import { PublicSourcesService } from '../public-source/public-source.service';
import { PublicFiscalYearsService } from '../public-fiscal-years/public-fiscal-years.service';

@Injectable({ providedIn: 'root' })
export class WordEmbedderService extends EmbedderService {
  constructor(
    formatNumberBrowserLocalePipe: FormatNumberBrowserLocalePipe,
    loaderStateService: LoaderStateService,
    publicDocumentsService: PublicDocumentsService,
    publicFiscalYearsService: PublicFiscalYearsService,
    publicIndicatorsService: PublicIndicatorsService,
    publicSourcesService: PublicSourcesService,
    translateService: TranslateService,
    unitSymbolPipe: UnitSymbolPipe,
  ) {
    super(
      formatNumberBrowserLocalePipe,
      loaderStateService,
      publicDocumentsService,
      publicFiscalYearsService,
      publicIndicatorsService,
      publicSourcesService,
      translateService,
      unitSymbolPipe,
    );
  }

  public embbedFileV2(
    settings: IContextSettings,
    embedderValue: EmbedderValue,
    documents: Record<string, MinimalDocumentMetaData>,
  ): Observable<void> {
    return this.run(async (context: Word.RequestContext) => {
      let item: Word.Range | Word.ContentControl | Word.Paragraph = context.document.getSelection();
      const value = embedderValue.value;
      const files = value?.value;

      if (value && Array.isArray(files)) {
        for (const file of files) {
          const fileId = String(file.file_id);
          const appendBlock = (field: EmbedderValueField, label: string): Promise<Word.ContentControl> =>
            this.embbedFileV2Block(context, settings, embedderValue, documents, item, field, fileId, label);

          item = await appendBlock(EmbedderValueField.value, 'File');

          if (value.type_details.display_url) {
            item = await appendBlock(EmbedderValueField.url, 'Document URL');
          }

          if (value.type_details.display_page_number) {
            item = await appendBlock(EmbedderValueField.page, 'Page number');
          }

          if (value.type_details.display_explanation) {
            item = await appendBlock(EmbedderValueField.explanation, 'Explanation');
          }

          item = item.insertParagraph('', 'After');
        }
      }

      await context.sync();
    });
  }

  public embbedTableAsList(settings: IContextSettings, embedderValue: EmbedderValue): Observable<void> {
    return this.run(async (context: Word.RequestContext) => {
      const { table, tableTotals } = embedderValue;

      if (!table) {
        return;
      }

      const range = context.document.getSelection();
      const paragraph = range.insertParagraph(this.getTableRowContextLabel(table[0]), 'After');
      const list = paragraph.startNewList();

      for (const valueGroup of [...table, ...(tableTotals ? [tableTotals] : [])]) {
        if (valueGroup !== table[0]) {
          const contextLabel = this.getTableRowContextLabel(valueGroup);
          const paragraph = list.insertParagraph(contextLabel, 'End');
          paragraph.listItem.level = 0;
        }

        const inputColumns = valueGroup.values?.filter((v) => v.type !== ValueDefinitionType.label) || [];
        for (const value of inputColumns) {
          const label = this.getTableRowInputLabel(value);
          const paragraph = list.insertParagraph(label, 'End');
          paragraph.listItem.level = 1;

          const fieldEmbedderValue = EmbedderUtils.formatTableEmbedderValue(embedderValue, value);
          const formattedValue = this.formatValue(fieldEmbedderValue, EmbedderValueField.value);
          const id = EmbedderUtils.formatId(
            settings,
            fieldEmbedderValue,
            formattedValue.value,
            EmbedderValueField.value,
          );
          const range = paragraph.insertText(String(formattedValue.value), 'End');
          const contentControl = range.insertContentControl('PlainText');
          await this.formatContentControl(context, contentControl, id);
        }
      }
    });
  }

  public embbedTableAsTable(settings: IContextSettings, embedderValue: EmbedderValue): Observable<void> {
    return this.run(async (context: Word.RequestContext) => {
      const { table } = embedderValue;

      if (!table) {
        return;
      }

      const rows = table.length + 1;
      const cols = Number(table[0].values?.length);
      const range = context.document.getSelection();
      const { ids: embedderValueIds, values: tableValues } = this.getTableValues(settings, embedderValue, table);

      const embeddedTable = range.insertTable(rows, cols, 'After', chunk(tableValues, cols));

      for (const [key, id] of Object.entries(embedderValueIds)) {
        const [row, col] = key.split('-');
        const cell = embeddedTable.getCell(Number(row), Number(col));
        const contentControl = cell.body.insertContentControl('PlainText');
        await this.formatContentControl(context, contentControl, id);
      }
    });
  }

  public embbedValue(
    settings: IContextSettings,
    documents: Record<string, MinimalDocumentMetaData>,
    embedderValue: EmbedderValue,
    field: EmbedderValueField,
    fieldId?: string,
    options: EmbedderInsertOptions = {},
  ): Observable<void> {
    const { html, value: formattedValue } = this.formatValue(embedderValue, field, fieldId, documents);
    const id = EmbedderUtils.formatId(settings, embedderValue, formattedValue, field, fieldId);

    if (Array.isArray(formattedValue)) {
      return this.insertList(id, formattedValue, options);
    } else if (html) {
      return this.insertHtml(id, formattedValue, options);
    } else {
      return this.insertText(id, formattedValue, options);
    }
  }

  public getEmbedderValueIds(options?: UpdateEmbedderValuesOptions): Observable<EmbedderValueId[]> {
    return this.run(async (context: Word.RequestContext) => {
      const controls = await this.getControls(context);
      const embbededValueIds: EmbedderValueId[] = controls.items
        .filter((control: Word.ContentControl) => control.tag)
        .map((control: Word.ContentControl) => EmbedderUtils.fetchId(control.tag))
        .filter((id: EmbedderValueId | undefined) => id) as EmbedderValueId[];
      return this.filterEmbedderValueIds(embbededValueIds, options);
    });
  }

  public getSelectedEmbedderValues(): Observable<EmbedderValueId[]> {
    return this.run(async (context) => {
      const range = context.document.getSelection();
      const controls = range.getContentControls();
      context.load(controls, 'items');
      await context.sync();

      return controls.items
        .map((control: Word.ContentControl) => EmbedderUtils.fetchId(control.tag))
        .filter((id: EmbedderValueId | undefined) => id) as EmbedderValueId[];
    });
  }

  public setControlsFormat(settings?: IContextSettings): Observable<void> {
    const isOverallUpdate = typeof settings === 'undefined';

    return this.run(async (context) => {
      const controls = await this.getControls(context);

      this.editableControls = Boolean(settings?.editableControls);
      this.highlightControls = isOverallUpdate ? this.highlightControls : settings.highlightControls;
      await Promise.all(
        controls.items.map((control: Word.ContentControl) => this.formatContentControl(context, control)),
      );
    });
  }

  protected highlightControl(controlId: string, highlightColor: EmbedderHighlightColors | null): Observable<void> {
    return this.run(async (context: Word.RequestContext) => {
      const controls = await this.getControls(context);
      const control = controls.getByTag(controlId).getFirst();
      this.setHighlightColor(control, highlightColor);

      if (highlightColor === EmbedderHighlightColors.deleted) {
        this.setColor(control, EmbedderFontColors.deleted);
        control.delete(true);
      }

      await context.sync();
    });
  }

  protected run<T>(execute: (context: Word.RequestContext) => Promise<T>): Observable<T> {
    return defer(() => from(Word.run(execute)));
  }

  private async appendComplement(
    context: Word.RequestContext,
    control: Word.ContentControl,
    options: EmbedderInsertOptions = {},
  ): Promise<void> {
    if (options.complement) {
      const text = control.getRange('After').insertText(options.complement, 'End');
      text.font.set({ highlightColor: EmbedderHighlightColors.none });
      text.select('End');
    } else {
      control.getRange('After').select('End');
    }

    await context.sync();
  }

  private async fetchCurrentFont(context: Word.RequestContext, range: Word.Range): Promise<Word.Font> {
    context.load(range);
    context.load(range.paragraphs);
    await context.sync();

    const currentParagraph = range.paragraphs.items[0];
    if (currentParagraph.text) {
      return range.getRange('Start').font;
    } else {
      const previousParagraph = currentParagraph.getPreviousOrNullObject();
      context.load(previousParagraph);
      await context.sync();

      return previousParagraph.isNullObject ? context.document.body.font : previousParagraph.font;
    }
  }

  private async getControls(context: Word.RequestContext): Promise<Word.ContentControlCollection> {
    const controls = context.document.getContentControls();
    context.load(controls, 'items');
    await context.sync();

    return controls;
  }

  private insert(
    id: string,
    execute: (control: Word.ContentControl) => void,
    options: EmbedderInsertOptions,
  ): Observable<void> {
    return this.run(async (context: Word.RequestContext) => {
      let wordContentControl: Word.ContentControl;
      let currentFont: Word.Font;

      if (options.controlId) {
        const controls = await this.getControls(context);
        wordContentControl = controls.getByTag(options.controlId).getFirstOrNullObject();
        await context.sync();

        if (wordContentControl.isNullObject) {
          return;
        }

        currentFont = wordContentControl.font;
        wordContentControl.set({ cannotEdit: false, removeWhenEdited: false });
        wordContentControl.clear();
        await context.sync();
      } else {
        let range = context.document.getSelection();
        currentFont = await this.fetchCurrentFont(context, range);

        const parentContentControl = range.parentContentControlOrNullObject;
        context.load(parentContentControl);
        await context.sync();

        if (!parentContentControl.isNullObject) {
          parentContentControl.getRange('After').select('End');
          range = context.document.getSelection();
          context.load(range);
          await context.sync();
        }

        if (!range.isEmpty) {
          range.clear();
        }

        wordContentControl = range.insertContentControl();
      }

      context.load(currentFont);
      await context.sync();

      execute(wordContentControl);

      context.load(wordContentControl);
      await context.sync();

      const paragraphs = wordContentControl.paragraphs;
      context.load(paragraphs);
      await context.sync();

      const font = {
        bold: Boolean(currentFont.bold),
        color: currentFont.color || '#000000',
        doubleStrikeThrough: Boolean(currentFont.doubleStrikeThrough),
        highlightColor: currentFont.highlightColor,
        italic: Boolean(currentFont.italic),
        name: currentFont.name,
        size: currentFont.size,
        strikeThrough: Boolean(currentFont.strikeThrough),
        subscript: Boolean(currentFont.subscript),
        superscript: Boolean(currentFont.superscript),
        underline: currentFont.underline,
      };
      paragraphs.items.forEach((p: Word.Paragraph) => p.font.set(font));
      await context.sync();

      await this.formatContentControl(context, wordContentControl, id, options.highlightColor);
      await this.appendComplement(context, wordContentControl, options);

      if (!options.controlId) {
        const paragraph = context.document.getSelection().paragraphs.getFirstOrNullObject();
        context.load(paragraph);
        await context.sync();

        if (paragraph.isListItem) {
          paragraph.detachFromList();
          await context.sync();
        }
      }
    });
  }

  private async embbedFileV2Block(
    context: Word.RequestContext,
    settings: IContextSettings,
    embedderValue: EmbedderValue,
    documents: Record<string, MinimalDocumentMetaData>,
    item: Word.Range | Word.ContentControl | Word.Paragraph,
    field: EmbedderValueField,
    fieldId: string,
    label: string,
  ): Promise<Word.ContentControl> {
    const { value: formattedValue } = this.formatValue(embedderValue, field, fieldId, documents);
    const id = EmbedderUtils.formatId(settings, embedderValue, formattedValue, field, fieldId);
    const paragraph = item.insertParagraph(`${label}: `, 'After');
    paragraph.font.set({ bold: true });
    const text = paragraph.insertText(String(formattedValue), 'End');
    text.font.set({ bold: false });
    const contentControl = text.insertContentControl('PlainText');
    await this.formatContentControl(context, contentControl, id);

    return contentControl;
  }

  private async formatContentControl(
    context: Word.RequestContext,
    control: Word.ContentControl,
    id?: string,
    highlightColor?: EmbedderHighlightColors,
  ): Promise<void> {
    control.set({ cannotEdit: false, removeWhenEdited: true, tag: String(id || control.tag) });
    this.setHighlightColor(control, highlightColor || null);
    control.set({ cannotEdit: !this.editableControls });
    await context.sync();
  }

  private getTableRowContextLabel(valueGroup: ValueGroup): string {
    const contextColumns = valueGroup.values?.filter((v) => v.type === ValueDefinitionType.label);
    return contextColumns?.map((c) => c.type_details.value).join(', ') || 'Total(s)';
  }

  private insertHtml(id: string, value: string, options: EmbedderInsertOptions): Observable<void> {
    return this.insert(id, (control) => control.insertHtml(value, 'Start'), options);
  }

  private insertList(id: string, values: string[], options: EmbedderInsertOptions): Observable<void> {
    return this.insert(
      id,
      (control) => {
        const paragraph = control.insertParagraph(values[0], 'Start');
        const list = paragraph.startNewList();

        values.slice(1).forEach((v) => list.insertParagraph(v, 'End'));
      },
      options,
    );
  }

  private insertText(id: string, value: string, options: EmbedderInsertOptions): Observable<void> {
    return this.insert(id, (c) => c.insertText(value, 'Start'), options);
  }

  private setColor(control: Word.ContentControl, color: EmbedderFontColors): void {
    control.font.set({ color });
  }

  private setHighlightColor(control: Word.ContentControl, color?: EmbedderHighlightColors | null): void {
    const highlightColor =
      color ?? (this.highlightControls ? EmbedderHighlightColors.standard : EmbedderHighlightColors.none);
    control.font.set({ highlightColor });
  }
}
