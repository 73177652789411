import { Injectable } from '@angular/core';
import { FeatureFlagKeys, FeatureFlagService, EvaluatedFeatureFlag } from '@novisto/common';
import { BehaviorSubject, Observable } from 'rxjs';
import { PublicFeatureFlagApiService } from './public-feature-flag-api.service';

@Injectable({
  providedIn: 'root',
})
export class PublicFeatureFlagService extends FeatureFlagService {
  private _clientReady: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private featureFlags: EvaluatedFeatureFlag[] = [];

  constructor(private publicFeatureFlagApiService: PublicFeatureFlagApiService) {
    super();
  }

  init(): void {
    this.publicFeatureFlagApiService.evaluateAllFeatureFlags().subscribe({
      next: (response) => {
        this.setFeatureFlags(response.data);
      },
      error: (error: unknown) => {
        console.error(error);
      },
    });
  }

  areAnyFeatureFlagsEnabled(flags: FeatureFlagKeys[]): boolean {
    return this.featureFlags.some((f) => flags.includes(f.key) && f.value);
  }

  getEnabledFeatureFlags(flags: FeatureFlagKeys[]): FeatureFlagKeys[] {
    return this.featureFlags.filter((f) => flags.includes(f.key) && f.value).map((f) => f.key);
  }

  areFeatureFlagsLoaded$(): Observable<boolean> {
    return this._clientReady.asObservable();
  }

  private setFeatureFlags(flags: EvaluatedFeatureFlag[]): void {
    this.featureFlags = flags;
    this._clientReady.next(true);
  }
}
